import { Cache } from 'aws-amplify'
import dayjs from 'dayjs'
import { Field, Form, Formik } from 'formik'
import { Link, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import AccountPlansAccordion from 'components/page/account/plan/AccountPlansAccordion'
import FeedbackForm from 'components/ui/feedback-form/FeedbackForm'
import EditFutureOrderModal from 'components/ui/modal/EditFutureOrderModal'
import ErrorModal from 'components/ui/modal/ErrorModal'
import OrderModal from 'components/ui/modal/OrderModal'
import StopDeliveriesModal from 'components/ui/modal/StopDeliveriesModal'
import OrderSummeryAccordion from './OrderSummeryAccordion'

import { RootState } from 'state'
import { updateDogsToDelete, updatePlanRadio } from 'state/actions/account/accountDogAction'
import { resetErrorMessageForPlans, updateOrders, updatePlans } from 'state/actions/account/accountPlanAction'
import { createDogsExistingPlan, resetFlowDogs } from 'state/actions/account/flow/dogAction'
import { removeAddons, removeRecipes, resetFlowPlan } from 'state/actions/account/flow/planAction'
import { resetFlowShipping } from 'state/actions/account/flow/shippingAction'
import { resetKey } from 'state/actions/keyAction'
import Api from 'state/apis/api'

import cancelIcon from '../../../../images/cancelIcon.svg'
import loadingGIF from '../../../../images/loadingspinner.gif'
import pauseIcon from '../../../../images/pauseIcon.svg'

const Plan: React.FC = () => {
  const {
    plans,
    orders,
    shippingDates,
    planFailError,
    errorMessage: planScheduleError,
    postReschedulePlanStatus
  } = useSelector((state: RootState) => state.accountPlan)
  const { summary }: { summary: AccountSummaryType } = useSelector((state: RootState) => state.accountSummary)
  const { changed } = useSelector((state: RootState) => state.accountDogShippingFlow)
  const [showPopup, setShowPopup] = useState(false)
  const [showOrderModal, setShowOrderModal] = useState(false)
  const [showPauseCancelModal, setShowPauseCancelModal] = useState(false)
  const [activeOrder, setActiveOrder] = useState(undefined)
  const [zendeskScript, setZendeskScript] = useState(null)
  // const [errorMessage, setErrorMessage] = useState('')
  const [nextPlan, setNextPlan] = useState<any>({})
  const dispatch = useDispatch()
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const [loading, setLoading] = useState(true)
  const [redZoneConfirmButton, setRedZoneConfirmButton] = useState(<></>)
  const [redZoneModalShow, setRedZoneModalShow] = useState(false)
  const [showYotpoReferralPopup, setShowYotpoReferralPopup] = useState(false)
  const [pausedCancelledSub, setPausedCancelledSub] = useState<any | undefined>({})
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const [feedbackSubmitError, setFeedbackSubmitError] = useState(false)
  const [feedbackSubmittedMsg, setFeedbackSubmittedMsg] = useState('')
  const [feedbackSubmitting, setFeedbackSubmitting] = useState(false)
  const [promotionalCredits, setPromotionalCredits] = useState(0)
  const [refundableCredits, setRefundableCredits] = useState(0)
  const [hasCredit, setHasCredit] = useState(false)

  useEffect(() => {
    // Set the subscription for the feedback form
    const pausedCancelledSub = plans?.filter(
      (p: any) => ['cancelled', 'paused'].includes(p.status) && p.askPauseCancelFeedback === true
    )
    setPausedCancelledSub(pausedCancelledSub)
  }, [plans])

  useEffect(() => {
    //  Set promotional credits if only 1 plan
    if (plans?.length === 1) {
      if (plans[0]?.promotionalCreditsApplied > 0) setPromotionalCredits(plans[0]?.promotionalCreditsApplied)
      if (plans[0]?.refundableCreditsApplied > 0) setRefundableCredits(plans[0]?.refundableCreditsApplied)
    } else {
      // reset promotional credit state if another dog/plan added
      setPromotionalCredits(0)
      setRefundableCredits(0)
    }
    // Check if credits exist
    const promotionalCreditPlans = plans?.filter(p => p.promotionalCreditsApplied > 0)
    if (promotionalCreditPlans?.length > 0) {
      setHasCredit(true)
    } else {
      setHasCredit(false)
    }
  }, [plans])

  useEffect(() => {
    let nextPlanData: Array<any> = []
    const orderData: any[] = orders?.length ? [...orders] : []
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    if (plans?.length > 0) {
      plans?.map(plan => {
        if (plan.status === 'active' || plan.status === 'future') {
          nextPlanData.push(plan)
        }
      })
    }

    if (orderData && orderData[0]) {
      orderData[0].nextDelivery = dayjs(orderData[0].shipDate).add(1, 'day').format('YYYY-MM-DD')
      nextPlanData.push(orderData[0])
    }

    if (nextPlanData && nextPlanData?.length > 0) {
      nextPlanData.sort((a, b) => {
        return new Date(a.nextDelivery) - new Date(b.nextDelivery)
      })
    }

    nextPlanData = nextPlanData.filter(i => i)

    if (nextPlanData && nextPlanData?.length > 0) {
      let totalQuantity = 0
      const res = nextPlanData?.[0]?.recipes?.map((recipe: any) => {
        totalQuantity += recipe.quantity

        // Check to determine whether the first element's data is derived from an order or recipe
        // if recipe has ID, its a recipe object, else its from an order
        if (typeof recipe.id !== 'undefined') {
          return recipe.quantity ? `${recipe.quantity} x ${recipe.name}` : `${recipe.name}`
        } else {
          return `${recipe.quantity} x ${recipe.description}`
        }
      })
      const addons = nextPlanData?.[0]?.addons?.map((addon: any) => {
        if (typeof addon.id !== 'undefined') {
          return addon.quantity ? `${addon.quantity} x ${addon.name}` : `${addon.name}`
        } else {
          return `${addon.quantity} x ${addon.description}`
        }
      })

      const totalGram = nextPlanData?.[0]?.plan?.split('-')[0]
      setNextPlan({
        nextDelivery: dayjs(nextPlanData?.[0]?.nextDelivery).format('dddd Do MMMM YYYY'),
        quantityPack: totalQuantity ? `${totalQuantity} x ${totalGram}` : totalGram,
        items: [...res],
        addons: [...addons],
        subscriptionId: nextPlanData?.[0]?.subscriptionId,
        validDeliveryDate: true,
        address: nextPlanData?.[0]?.address
      })
    }
    //typeof nextPlanData?.[0]?.validDeliveryDate !== 'undefined' ? nextPlanData?.[0]?.validDeliveryDate : true
  }, [plans, orders])

  useEffect(() => {
    if (planFailError || planScheduleError) {
      setShowPopup(true)
    } else {
      setShowPopup(false)
    }
  }, [planFailError, planScheduleError])

  useEffect(() => {
    const updatePlansAndOrders = async () => {
      await dispatch(updatePlans())
      await dispatch(updateOrders())
      setLoading(false)
    }

    updatePlansAndOrders()
    dispatch(resetFlowDogs())
    dispatch(resetFlowPlan())
    dispatch(resetFlowShipping())
    dispatch(removeRecipes())
    dispatch(removeAddons())
    dispatch(resetKey())
    dispatch(updateDogsToDelete([]))
    //dispatch(getPlanSchedule())
  }, [showPauseCancelModal])

  useEffect(() => {
    const zendeskScript = (
      <script type="text/javascript">{`
        if (typeof window.zE !== 'undefined') {
          window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'plan' })
        }
    `}</script>
    )
    // This is a hack because zendesk takes a long time to load onto the page
    window.setTimeout(() => {
      setZendeskScript(zendeskScript)
    }, 1000)
  }, [])

  useEffect(() => {
    if (postReschedulePlanStatus || changed) setShowYotpoReferralPopup(true)
  }, [postReschedulePlanStatus, changed])

  useEffect(() => {
    const yotpoAccountReferralPopup: string = Cache.getItem('_yotpo_account_referral_popup') ?? null
    if (showYotpoReferralPopup && !yotpoAccountReferralPopup && summary && window?.yotpoWidgetsContainer) {
      window.yotpoWidgetsContainer.initWidgets()

      setTimeout(() => window.yotpoWidgetsContainer.initWidgets(), 2000)
      const expiration = dayjs().add(14, 'day')
      setShowYotpoReferralPopup(false)
      Cache.setItem('_yotpo_account_referral_popup', true, { expires: expiration.toDate().getTime() })
    }
  }, [summary, showYotpoReferralPopup])

  const _validationSchema = Object.assign({})

  const getOrderStatus = (order: any) => {
    const orderDate = new Date(order.shipDate)
    const timeNow = new Date()

    if (order.status === 'Shipped' && orderDate > timeNow) {
      return 'Awaiting Shipment'
    }

    if (order.status === 'delivered') {
      return 'Shipped'
    }
    return order.status[0].toUpperCase() + order.status.substring(1)
  }

  const getQuantityAndPackSize = ({ quantity, weeks, plan }) => {
    return quantity + ' x ' + plan + ' packs every ' + weeks + ' weeks'
  }

  const showBox = plans?.filter(p => p.redZone).length ? true : false

  const _getDogNames = (plans: any, subscriptionId: string) => {
    if (plans && subscriptionId) {
      const plan = plans.filter(p => p.subscriptionId === subscriptionId)[0]
      return plan.dogNames
    }
    return ''
  }

  const reactivatePlan = async () => {
    await dispatch(updatePlanRadio(pausedCancelledSub[0]?.subscriptionId))
    await dispatch(
      createDogsExistingPlan({
        subscriptionId: pausedCancelledSub[0]?.subscriptionId,
        dogs: pausedCancelledSub[0]?.dogs
      })
    )
    const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
    navigate(url)
  }

  const getPlans = plans => {
    if (plans?.filter(p => p.status === 'active' || p.status === 'future').length === 0) {
      return false
    } else {
      return true
    }
  }

  if (loading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  if (!loading && plans?.length === 0) {
    return (
      <div className="rounded-xl border border-ddCustomise bg-ddYellow p-4">
        <h3 className="mb-4 text-2xl">No active plans</h3>
        <div className="leading-7">
          <p>
            Please first check your payment details are up to date. Following this go to the dogs page and create a new
            dog. Complete the steps to set up a new plan.
          </p>
          <p>Please contact customer delight if you have any issues.</p>
        </div>
      </div>
    )
  }

  let _quantity = 0

  if (orders && orders[0] && orders[0]?.recipes && orders[0]?.recipes?.length > 0) {
    orders[0].recipes.map(item => {
      _quantity += item.quantity
    })
  }

  return (
    !loading && (
      <>
        {summary?.customer?.email && summary?.customer?.customerId && (
          <div
            id="swell-customer-identification"
            data-authenticated="true"
            data-email={summary?.customer?.email}
            data-id={summary.customer?.customerId}
            className="display:none;"
          ></div>
        )}
        {process.env.GATSBY_YOTPO_REFERRAL_THANK_YOU && (
          <div
            className="yotpo-widget-instance"
            data-yotpo-instance-id={process.env.GATSBY_YOTPO_REFERRAL_THANK_YOU}
          ></div>
        )}
        <div className="min-h-screen">
          <div className="flex flex-1 flex-col">
            <main>
              <h2 data-testid="yourDogsPlan" className="mt-8 mb-4 text-center text-3xl sm:text-5xl">
                {plans?.length === 1 ? "Your Dog's Plan" : "Your Dogs' Plans"}
              </h2>

              {nextPlan && nextPlan?.validDeliveryDate === false && (
                <div className="relative mx-auto mt-8 mb-4 w-full rounded-xl border border-[#FB5D34] bg-[#FAD1CD] p-4 pl-16 text-sm">
                  <StaticImage
                    width={64}
                    className="absolute -top-2 -left-2 md:-left-4 md:-top-4"
                    src="../../../../images/change-delivery-date.svg"
                    alt="Delivery date unavailable"
                    imgStyle={{ position: 'absolute' }}
                    style={{ position: 'absolute' }}
                  />
                  <p>
                    Your next delivery date is unavailable. Please select another below or contact Customer Delight for
                    support.
                  </p>
                </div>
              )}

              {/* Feedback form */}
              {pausedCancelledSub?.length > 0 && (
                <Formik
                  initialValues={{
                    message: ''
                  }}
                  validationSchema={yup.object({
                    message: yup
                      .string()
                      .required('Please add feedback')
                      .test('other validation', 'Plain text only', value => {
                        if (value?.match(/^[+a-z0-9\s().,\-_!?"']*$/gi)) {
                          return true
                        }
                        return false
                      })
                  })}
                  onSubmit={async values => {
                    setFeedbackSubmitting(true)
                    let pauseCancel = 'cancel'
                    if (pausedCancelledSub[0]?.status === 'paused') pauseCancel = 'pause'
                    let url = spoofMode
                      ? `/v1/private/admin/account/plan/${pauseCancel}?userId=` + spoofedUserId
                      : `/v1/private/account/plan/${pauseCancel}`

                    Api.put('wwwREST', url, {
                      body: {
                        subscriptionId: pausedCancelledSub[0]?.subscriptionId,
                        feedback: values.message
                      }
                    })
                      .then(() => {
                        setFeedbackSubmitted(true)
                        setFeedbackSubmittedMsg('Thank you for your feedback!')
                        dispatch(resetErrorMessageForPlans())
                        setFeedbackSubmitting(false)
                      })
                      .catch((err: any) => {
                        console.error(JSON.stringify(err, null, 2))
                        setFeedbackSubmitError(true)
                        setFeedbackSubmittedMsg('There is an issue submitting your feedback. Please try again later.')
                        setFeedbackSubmitting(false)
                      })
                  }}
                >
                  {props => (
                    <div className="bg-ddGreen flex w-full lg:w-1/2 mr-4 lg:mx-auto md:min-w-[500px] lg:max-w-[1000px] rounded-3xl justify-center items-center my-8">
                      <Form className="mx-auto w-full text-center md:max-w-[80%]">
                        <div className="grid grid-rows-1 grid-flow-col gap-4 mt-4">
                          <div className="row-span-3 ">
                            {' '}
                            {pausedCancelledSub[0]?.status === 'paused' && (
                              <img width={40} src={pauseIcon} alt="Pause" className="block" />
                            )}
                            {pausedCancelledSub[0]?.status === 'cancelled' && (
                              <img width={40} src={cancelIcon} alt="Cancel" className="block" />
                            )}
                          </div>
                          <div className="col-span-2 text-left">
                            {' '}
                            <h3>
                              {pausedCancelledSub[0]?.dogNames}'s plan has been{' '}
                              {pausedCancelledSub[0]?.status === 'cancelled' ? 'Cancelled' : ''}{' '}
                              {pausedCancelledSub[0]?.status === 'paused' ? 'Paused' : ''}
                            </h3>
                          </div>
                          <div className="row-span-2 col-span-2 ">
                            {' '}
                            {pausedCancelledSub[0]?.nextDelivery && (
                              <p className="font-sans-serif mb-4 text-left text-sm font-normal">
                                Your last delivery date will be:
                                <span className="font-bold">DATE</span>
                              </p>
                            )}
                            {!pausedCancelledSub[0]?.nextDelivery && (
                              <p className="font-sans-serif mb-4 text-left text-sm font-normal">
                                You have no upcoming deliveries.
                              </p>
                            )}
                          </div>
                        </div>
                        <p className="font-sans-serif mb-4 text-center text-sm font-normal">
                          You can{' '}
                          <button
                            className="underline"
                            onClick={() => {
                              reactivatePlan()
                            }}
                          >
                            {pausedCancelledSub[0]?.status === 'paused' && 'resume'}
                            {pausedCancelledSub[0]?.status === 'cancelled' && 'reactivate'}
                          </button>
                          <span> {pausedCancelledSub[0]?.dogNames}'s</span> Plan anytime.
                        </p>

                        <div className="mb-1">
                          <p className="font-sans-serif mb-4 text-center text-sm font-normal">
                            We're on a mission to change as many dogs lives with real food as possible and would love
                            your feedback so we can continue to grow and improve.
                          </p>
                        </div>
                        {feedbackSubmitted && (
                          <p className="font-sans-serif my-16 pb-4 text-center text-sm font-bold">
                            {feedbackSubmittedMsg}
                          </p>
                        )}
                        {!feedbackSubmitted && (
                          <>
                            <div className="mb-6 w-[96%] mx-auto lg:w-full">
                              <Field
                                className="w-full rounded-xl focus:border-[#B0CDD5] focus:outline-none focus:ring-[#B0CDD5]"
                                name="message"
                                as="textarea"
                                placeholder="Share your feedback with us"
                                rows={3}
                              />
                              {feedbackSubmitError && <p className="text-xs text-red-500">{feedbackSubmittedMsg}</p>}
                              {props.errors.message && props.touched.message ? (
                                <p className="text-xs text-red-500">{props.errors.message}</p>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="mx-auto mb-6 flex w-max flex-col">
                              <button
                                type="submit"
                                className="btn-cta disabled:opacity-50"
                                disabled={feedbackSubmitting}
                              >
                                Send feedback
                              </button>
                            </div>
                          </>
                        )}
                      </Form>
                    </div>
                  )}
                </Formik>
              )}
              {showBox && nextPlan && <OrderSummeryAccordion nextPlan={nextPlan} />}
              <AccountPlansAccordion
                items={plans?.map(plan => {
                  return {
                    dogs: plan.dogs,
                    dogNames: plan.dogNames,
                    status: plan.status[0].toUpperCase() + plan.status.substring(1),
                    subscriptionId: plan.subscriptionId,
                    // images: data.allImageSharp.edges,
                    redZone: plan.redZone,
                    isInTrial: plan.isInTrial,
                    plan: plan.plan,
                    nextPlan: plan.nextPlan,
                    quantity: plan.quantity,
                    validDeliveryDate: plan.validDeliveryDate,
                    packInfo: {
                      quantityAndPackSize: getQuantityAndPackSize({
                        quantity: plan.quantity,
                        weeks: plan.weeks,
                        plan: plan.plan
                      }),
                      deliveryTo: {
                        name: plan.address.shipping?.name,
                        surname: plan.address.shipping?.surname,
                        address1: plan.address.shipping.address1,
                        address2: plan.address.shipping.address2,
                        city: plan.address.shipping.city,
                        county: plan.address.shipping.county,
                        postcode: plan.address.shipping.postcode
                      },
                      targetPaymentDate: plan.payment.targetPaymentDate,
                      nextPaymentDate: plan.nextPayment,
                      nextDeliveryDate: plan.nextDelivery,
                      price: plan.price,
                      recipes: plan.recipes,
                      addons: plan.addons,
                      coupons: plan.coupons,
                      promotionalCredits,
                      refundableCredits,
                      hasCredit
                    }
                  }
                })}
              />

              {orders && orders.length > 0 && (
                <div className="rounded-3xl bg-[#F7F4D4] p-4 lg:mx-auto lg:w-1/2">
                  <h3 className="mb-4 text-2xl">Recent orders</h3>
                  {orders &&
                    orders.map(order => (
                      <div className="mb-2 flex flex-row justify-between">
                        <p>
                          Order no: {order.id}. {getOrderStatus(order)} {dayjs(order.shipDate).format('DD/MM/YYYY')}
                        </p>
                        <button
                          className="btn-secondary"
                          onClick={() => {
                            setActiveOrder(order)
                            setShowOrderModal(true)
                            if (typeof window.gtag !== 'undefined') {
                              window.gtag('event', 'clicked_view_order', {
                                page: 'account_plan',
                                order_id: order.id,
                                order_ship_date: order.shipDate,
                                order_subscription_id: order.subscription_id
                              })
                            }
                          }}
                        >
                          View
                        </button>
                      </div>
                    ))}
                </div>
              )}
              {getPlans(plans) && (
                <div className="mx-auto my-4 flex h-auto w-full flex-col overflow-hidden rounded-3xl md:flex-row">
                  <div className="mx-auto mt-2 w-full rounded-3xl bg-beigeBG p-4 text-center md:mt-0 md:w-1/2">
                    <button
                      className="btn-secondary"
                      onClick={() => {
                        const plan = plans?.filter(p => p.status === 'active' || p.status === 'future')
                        if (plan?.length === 1 && plan[0]?.redZone)
                          setRedZoneConfirmButton(
                            <button
                              type="button"
                              className="btn-cta inline-flex justify-center px-4 py-2 sm:col-start-1 sm:mt-0"
                              onClick={() => {
                                setRedZoneModalShow(false)
                                setShowPauseCancelModal(true)
                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'select_stop_deliveries', {
                                    page: 'account_plan',
                                    subscription_id: plan[0].subscriptionId
                                  })
                                }
                              }}
                            >
                              Edit plan
                            </button>
                          )
                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'select_stop_deliveries', {
                            page: 'account_plan',
                            subscription_id: plan?.map(p => p.subscriptionId)
                          })
                        }
                        setShowPauseCancelModal(true)
                      }}
                    >
                      Stop deliveries
                    </button>
                  </div>
                </div>
              )}
            </main>
          </div>
        </div>
        <Helmet>{zendeskScript && zendeskScript}</Helmet>
        {activeOrder && <OrderModal show={showOrderModal} setShow={setShowOrderModal} orderItem={activeOrder} />}
        {getPlans(plans) && (
          <StopDeliveriesModal
            show={showPauseCancelModal}
            setShow={setShowPauseCancelModal}
            plans={plans?.filter(p => p.status === 'active' || p.status === 'future')}
          />
        )}
        <EditFutureOrderModal
          show={redZoneModalShow}
          setShow={setRedZoneModalShow}
          confirmButton={redZoneConfirmButton}
        />
        {(planScheduleError?.popup || planFailError?.popup) && (
          <ErrorModal
            show={showPopup}
            setShow={setShowPopup}
            errorMessage={planScheduleError?.popup?.message || planFailError?.popup?.message}
            url={planScheduleError?.popup?.url || planFailError?.popup?.url}
            action={planScheduleError?.popup?.action || planFailError?.popup?.action}
            actionText={planScheduleError?.popup?.actionText || planFailError?.popup?.actionText}
          />
        )}
        <FeedbackForm source={'account'} />
      </>
    )
  )
}

export default Plan
