import { navigate } from '@reach/router'
import { Formik } from 'formik'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import {
  getPlanId,
  postPlanId,
  postPlanIdForTrial,
  removeAddons,
  removeRecipes,
  resetErrorMessage,
  updateChangedStatus,
  updatePlanEditedStatus
} from 'state/actions/account/flow/planAction'
import { CouponType, DogType, PackType } from 'state/types/dogType'
import { PlanDetailSchema } from 'utils/yup-validation-schema'

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FaqAccordion from 'components/ui/faq-accordion'
import ErrorModal from 'components/ui/modal/ErrorModal'
import YesNoModal from 'components/ui/modal/YesNoModal'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import { HeadingClass, HeadingType } from 'enums'
import { useFormikContext } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import { updateKey } from 'state/actions/keyAction'
import PlansAccordion from './PlansAccordion'

interface PlanCtxState {
  pack: PackType | undefined
  packSize: number | undefined
  noOfPacks: number | undefined
  weeks: number | undefined
  prevPackSize: number | undefined
  prevNoOfPacks: number | undefined
  prevWeeks: number | undefined
  packType: string | undefined
  fixedOrCustomPlan: string | undefined
  handleFixedOrCustomPlan: (plan: string) => void
  handleIsDefaultPlan: (isDefault: boolean) => void
  handlePackType: (packType: string) => void
  handleSetPackSize: (size: number) => void
  handleSetNoOfPacks: (size: number) => void
  handleSetWeeks: (noOfWeeks: number) => void
}

interface IDog {
  ageStage: string
  dob: string
  exercise: string
  name: string
  neutered: string
  physique: string
  weight: number
}

export const PlanCtx = React.createContext<PlanCtxState>({} as PlanCtxState)

const Plan = () => {
  const dispatch = useDispatch()
  const { pack, defaultPack, dogInfo, summary } = useSelector((state: RootState) => state.accountDogFlow)
  const { plan } = useSelector((state: RootState) => state.accountDogPlanFlow)
  const {
    noOfPacks: prevNoOfPacks,
    packSize: prevPackSize,
    weeks: prevWeeks,
    isDefaultPlan: prevIsDefaultPlan,
    packType: prevPackType,
    changed,
    loading,
    error,
    planEdited
  } = useSelector((state: RootState) => state.accountDogPlanFlow)

  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)

  const [isDefaultPlan, setIsDefaultPlan] = useState(true)
  const [packType, setPackType] = useState<string>('')
  const [packSize, setPackSize] = useState<number | undefined>()
  const [noOfPacks, setNoOfPacks] = useState<number | undefined>()
  const [weeks, setWeeks] = useState<number | undefined>()
  const [sortedDogInfo, setSortedDogInfo] = useState<Array<IDog>>([])
  const [fixedOrCustomPlan, setFixedOrCustomPlan] = useState('FIXED')
  const [showPopup, setShowPopup] = useState(false)
  const [openTab, setOpenTab] = useState('FULL')
  const [yesNoModalShow, setYesNoModalShow] = useState(false)
  const [yesAnswer, setYesAnswer] = useState(false)

  const [customisePackSizeSelection, setCustomisePackSizeSelection] = useState(null)
  const [customiseNoOfPacksSelection, setCustomiseNoOfPacksSelection] = useState(null)
  const [customiseWeeksSelection, setCustomiseWeeksSelection] = useState(null)
  const [shownSpoofTrialPopup, setShownSpoofTrialPopup] = useState(false)
  const [spoofOverride, setSpoofOverride] = useState(false)

  const faqs = [
    {
      question: 'How big are the packs of food?',
      answer: (
        <div>
          <p className="mb-4 text-sm font-bold">Approximate dimensions for our pack sizes (in cm):</p>
          <div className="mb-4 flex w-full flex-row flex-wrap justify-evenly gap-y-4">
            <div className="flex w-1/2 flex-col md:w-1/3">
              <p className="font-bold">250g</p>
              <p>14 x 14 x 5 (L x W x H)</p>
              <StaticImage className="w-full md:w-4/5" src="../../../../images/faq/faq-250g.png" alt="250g pack" />
            </div>
            <div className="flex w-1/2 flex-col md:w-1/3">
              <p className="font-bold">600g</p>
              <p>24 x 13 x 4 (L x W x H)</p>
              <StaticImage className="w-full md:w-4/5" src="../../../../images/faq/faq-600g.png" alt="250g pack" />
            </div>

            <div className="flex w-1/2 flex-col md:w-1/3">
              <p className="font-bold">1000g</p>
              <p>22 x 17 x 5 (L x W x H)</p>
              <StaticImage className="w-full md:w-4/5" src="../../../../images/faq/faq-1000g.png" alt="1000g pack" />
            </div>
          </div>
          <p>Our food is delivered frozen for freshness and can be kept in the freezer for up to 6 months.</p>
          <p className="mb-4">When needed, defrost and store in the fridge where they can be kept for up to 5 days.</p>
          <p>After your first box, you can change your pack size, quantity and frequency in your account section.</p>
        </div>
      )
    },
    {
      question: 'How many packs can I fit in my freezer?',
      answer: (
        <div>
          <div className="flex w-full flex-row">
            <div className="flex flex-col items-end justify-end">
              <p className="hidden w-full p-4 text-right font-bold md:flex md:w-2/3">Integrated fridge freezer</p>
            </div>
            <div className="mr-1 text-center md:mr-8">
              <p className="w-full p-4 font-bold md:hidden">Integrated fridge freezer</p>
              <StaticImage
                className="min-w-[50px]"
                src="../../../../images/faq/faq-1.svg"
                alt="Integrated fridge freezer"
              />
            </div>
            <div className="ml-1 text-center md:ml-8">
              <p className="w-full p-4 font-bold md:hidden">Freestanding fridge freezer</p>
              <StaticImage
                className="min-w-[50px]"
                src="../../../../images/faq/faq-2.svg"
                alt="Freestanding fridge freezer"
              />
            </div>
            <div className="flex flex-col justify-end">
              <p className="hidden w-full p-4 font-bold md:flex md:w-2/3">Freestanding fridge freezer</p>
            </div>
          </div>
          <div className="flex w-full flex-row justify-center">
            <div className="relative my-4 flex w-full flex-row justify-center gap-16 md:left-[-0.5rem] md:w-[280px]">
              <div className="flex">
                <p className="text-center">1 freezer shelf can fit up to:</p>
              </div>
              <div className="flex">
                <p className="text-center">1 freezer shelf can fit up to:</p>
              </div>
            </div>
          </div>
          <div className="relative mb-4 flex flex-col items-center justify-center gap-4">
            <div className="flex w-full flex-row text-center md:w-[380px] md:justify-start">
              <p className="mr-4 font-bold md:mr-12">250g</p>
              <span className="flex w-full flex-row justify-between pr-16 md:w-auto md:pr-0">
                <p className="md:mr-40">7</p>
                <p>10</p>
              </span>
            </div>
            <div className="flex w-full flex-row text-center md:w-[380px] md:justify-start">
              <p className="mr-4 font-bold md:mr-12">600g</p>
              <span className="flex w-full flex-row justify-between pr-16 md:w-auto md:pr-0">
                <p className="md:mr-40">4</p>
                <p>9</p>
              </span>
            </div>
            <div className="flex w-full flex-row text-center md:w-[392px] md:justify-start">
              <p className="mr-4 font-bold md:mr-12">1000g</p>
              <span className="flex w-full flex-row justify-between pr-16 md:w-auto md:pr-0">
                <p className="md:mr-40">3</p>
                <p>7</p>
              </span>
            </div>
            <p className="text-xs italic">Internal dimensions may vary, all numbers are approximate</p>
          </div>
          <div>
            <p className="mb-4">
              Depending on the size of your freezer, most Starter Boxes will fit in 1-2 drawers. Our food can be stored
              alongside your normal food in the fridge or freezer.
            </p>
            <p>
              In hot weather spells we put extra ice packs in your box to keep the food cool. On really hot days our
              food may arrive defrosted but as long as the packs are still cool to touch, its completely fine for you to
              refreeze.
            </p>
          </div>
        </div>
      )
    },
    {
      question: 'When can I change my plan?',
      answer: (
        <div>
          <p>
            We want to give you as much flexibility as possible, but we do prevent changes to orders 4 days before they
            are shipped to help our operations team.
          </p>
          <p>
            If you need to make changes in this time, contact Customer Delight. If your next order is already
            processing, changes will be applied to future orders.
          </p>
        </div>
      )
    }
  ]

  const backClick = () => {
    if (planEdited) {
      setYesNoModalShow(true)
    } else {
      const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
      navigate(url)
    }
  }

  useEffect(() => {
    if (yesAnswer) {
      const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
      navigate(url)
      setYesAnswer(false)
      dispatch(updatePlanEditedStatus(false))
    }
  }, [yesAnswer, planEdited])

  useEffect(() => {
    if (!summary?.length) {
      const url = spoofMode ? '/account/dogs/?userId=' + spoofedUserId : '/account/dogs/'
      navigate(url)
    }

    dispatch(resetErrorMessage())
  }, [])

  useEffect(() => {
    if (error) {
      setShowPopup(true)
    } else {
      setShowPopup(false)
    }
  }, [error])

  useEffect(() => {
    if (dogInfo.isInTrial) {
      if (!shownSpoofTrialPopup && spoofMode) {
        setShownSpoofTrialPopup(true)
        if (!confirm('Subscription is in a trial, press OK to proceed with the trial or Cancel to change the plan.')) {
          setSpoofOverride(true)
          setPackSize(pack?.fullTrial?.planId ? pack?.fullTrial?.packSize : pack?.partialTrial?.packSize)
          setNoOfPacks(pack?.fullTrial?.planId ? pack?.fullTrial?.numberOfPacks : pack?.partialTrial?.numberOfPacks)
          setWeeks(pack?.fullTrial?.planId ? pack?.fullTrial?.weeks : pack?.partialTrial?.weeks)
        }
      }

      if (!spoofOverride) {
        if (packType === 'FULL_TRIAL') {
          if (pack.fullTrial.planId) {
            dispatch(
              postPlanIdForTrial({
                planId: pack.fullTrial.planId,
                planObject: pack.fullTrial,
                noOfPacks: pack.fullTrial.numberOfPacks,
                packSize: pack.fullTrial.packSize,
                weeks: pack.fullTrial.weeks,
                isDefaultPlan: true,
                packType,
                fixedOrCustomPlan
              })
            )
          }
        } else if (packType === 'PARTIAL_TRIAL') {
          if (pack.partialTrial.planId) {
            dispatch(
              postPlanIdForTrial({
                planId: pack.partialTrial.planId,
                planObject: pack.partialTrial,
                noOfPacks: pack.partialTrial.numberOfPacks,
                packSize: pack.partialTrial.packSize,
                weeks: pack.partialTrial.weeks,
                isDefaultPlan: true,
                packType,
                fixedOrCustomPlan
              })
            )
          }
        }
      }
    }
  }, [dogInfo, packType])

  useEffect(() => {
    setPackType(defaultPack)
  }, [pack, defaultPack])

  useEffect(() => {
    if (prevPackType) setPackType(prevPackType)
  }, [prevPackType])

  useEffect(() => {
    if (packType) {
      setState()
    }
  }, [packType])

  /**
   * Populate state on page load and on packType change (FULL|PARTIAL|CUSTOM)
   */
  const setState = () => {
    if (packType === 'FULL') {
      setPackSize(pack.full.packSize)
      setNoOfPacks(pack.full.numberOfPacks)
      setWeeks(pack.full.weeks)
    } else if (packType === 'PARTIAL') {
      setPackSize(pack.partial.packSize)
      setNoOfPacks(pack.partial.numberOfPacks)
      setWeeks(pack.partial.weeks)
    } else if (packType === 'CUSTOM') {
      if (
        !prevPackSize &&
        !prevNoOfPacks &&
        !prevWeeks &&
        pack.custom &&
        pack.custom.packSize &&
        pack.custom.numberOfPacks &&
        pack.custom.weeks
      ) {
        setPackSize(pack.custom.packSize)
        setNoOfPacks(pack.custom.numberOfPacks)
        setWeeks(pack.custom.weeks)
        handleFixedOrCustomPlan('CUSTOM')
        setOpenTab('CUSTOM')
      }
    }
  }

  const handlePackType = (e: string) => {
    setPackType(e)
  }

  const handleIsDefaultPlan = (isDefault: boolean) => {
    setIsDefaultPlan(isDefault)
  }

  const handleSetPackSize = (size: number) => {
    setPackSize(size)
  }

  const handleSetNoOfPacks = (numOfPacks: number) => {
    setNoOfPacks(numOfPacks)
  }

  const handleSetWeeks = (noOfWeek: number) => {
    setWeeks(noOfWeek)
  }

  const handleFixedOrCustomPlan = (plan: string) => {
    setFixedOrCustomPlan(plan)
  }

  useEffect(() => {
    if (changed) {
      const url = spoofMode ? '/account/recipes/?userId=' + spoofedUserId : '/account/recipes/'
      navigate(url)
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    // We have to do this because the dogs object contains empty values
    const tempSortedDogInfo: Array<IDog> = []

    summary.map(dog => {
      if (dog.name && !dog.delete) {
        tempSortedDogInfo.push(dog)
      }
    })

    setSortedDogInfo(tempSortedDogInfo)
  }, [])

  const getTotalGrams = () => {
    let totalGrams = 0

    summary.map(dogInfo => {
      if (dogInfo.gramsPerDay) {
        totalGrams += dogInfo.gramsPerDay
      }
    })

    return totalGrams
  }

  const getPrice = () => {
    if (fixedOrCustomPlan === 'CUSTOM') {
      if (plan?.totalValue) {
        return plan.totalValue
      }

      return 0
    } else {
      if (packType === 'FULL') {
        return pack?.full.totalValue
      } else if (packType === 'PARTIAL') {
        return pack?.partial.totalValue
      } else if (packType === 'CUSTOM') {
        handleFixedOrCustomPlan('CUSTOM')
        if (plan?.totalValue) {
          return plan.totalValue
        }
      }
    }

    return 0
  }

  const getCostPerDay = () => {
    if (fixedOrCustomPlan === 'CUSTOM') {
      if (plan?.costPerDay) {
        return plan.costPerDay
      }

      return 0
    } else {
      if (packType === 'FULL') {
        return pack.full.costPerGrams || pack.full.costPerDay
      } else if (packType === 'PARTIAL') {
        return pack.partial.costPerDay
      } else if (packType === 'CUSTOM') {
        handleFixedOrCustomPlan('CUSTOM')
        if (plan?.costPerDay) {
          return plan.costPerDay
        }
      }
    }

    return 0
  }

  const selectionSameAsState = (w, n, p) => {
    return parseInt(w) === weeks && parseInt(n) === noOfPacks && parseInt(p) === packSize
  }

  const accordionItems = [
    {
      title: 'Update my Plan',
      type: 'custom'
    },
    {
      title: 'Our recommended plans',
      type: 'default'
    }
  ]

  return (
    <div>
      <div>
        <Formik
          key={`plansForm`}
          initialValues={{
            packType: prevPackType ? prevPackType : defaultPack
          }}
          validationSchema={PlanDetailSchema}
          onSubmit={async () => {
            let planId = `${packSize}g-${weeks}w`
            //console.log({planId, pack, packSize, weeks, noOfPacks})
            if (packType === 'FULL') {
              planId = pack.full.planId
            } else if (packType === 'PARTIAL') {
              planId = pack.partial.planId
            }

            await dispatch(removeRecipes())
            await dispatch(removeAddons())

            //console.log({packType, fixedOrCustomPlan})

            if (fixedOrCustomPlan === 'FIXED') {
              dispatch(
                getPlanId({
                  plan: pack[packType.toLowerCase()],
                  noOfPacks,
                  packSize,
                  weeks,
                  isDefaultPlan,
                  packType,
                  fixedOrCustomPlan
                })
              )
            } else {
              dispatch(
                postPlanId({
                  subscriptionId: dogInfo.subscriptionId,
                  planId,
                  noOfPacks,
                  packSize,
                  weeks,
                  isDefaultPlan,
                  packType,
                  fixedOrCustomPlan
                })
              )
            }
          }}
          validateOnMount
        >
          {props => (
            <>
              <div>
                <div>
                  <h1 className="mb-4 mt-2 text-center text-3xl tracking-tight sm:text-5xl">
                    Edit{' '}
                    {sortedDogInfo.map((dog, i) => {
                      if (i === sortedDogInfo.length - 1 && sortedDogInfo.length > 1) {
                        return ' and ' + dog.name
                      }

                      if (i > 0) {
                        return ', ' + dog.name
                      }

                      return dog.name
                    })}
                    's Plan
                  </h1>
                  <div className="mx-auto mb-4 w-full rounded-3xl bg-[#F7F4D4] p-4 md:max-w-[600px]">
                    <p className="text-center">
                      For a full diet we recommend{' '}
                      {summary
                        ?.filter(d => !d.delete)
                        .map((dogInfo, i, ref) => (
                          <span key={`gramsPerDay${i}`} className="text-center">
                            <span className="font-bold">{dogInfo.gramsPerDay}g</span> for {dogInfo.name}
                            {i === ref?.length - 1 ? '.' : i === ref?.length - 2 ? ' and ' : ', '}
                          </span>
                        ))}
                      {(summary?.filter(d => !d.delete)).length > 1 && (
                        <span className="mt-2 block text-center">
                          (<span className="font-bold">{getTotalGrams()}g</span> in total.)
                        </span>
                      )}
                    </p>
                  </div>
                  <PlanCtx.Provider
                    value={{
                      pack,
                      packSize,
                      noOfPacks,
                      weeks,
                      prevPackSize,
                      prevNoOfPacks,
                      prevWeeks,
                      packType,
                      fixedOrCustomPlan,
                      handleIsDefaultPlan,
                      handlePackType,
                      handleSetPackSize,
                      handleSetNoOfPacks,
                      handleSetWeeks,
                      handleFixedOrCustomPlan
                    }}
                  >
                    <PlansAccordion
                      items={accordionItems}
                      prevIsDefaultPlan={prevIsDefaultPlan}
                      isDefaultPlan={isDefaultPlan}
                      handleIsDefaultPlan={handleIsDefaultPlan}
                      handleFixedOrCustomPlan={handleFixedOrCustomPlan}
                      fixedOrCustomPlan={fixedOrCustomPlan}
                      packType={packType}
                      openTab={openTab}
                      setOpenTab={setOpenTab}
                      customisePackSizeSelection={customisePackSizeSelection}
                      customiseNoOfPacksSelection={customiseNoOfPacksSelection}
                      customiseWeeksSelection={customiseWeeksSelection}
                      setCustomisePackSizeSelection={setCustomisePackSizeSelection}
                      setCustomiseNoOfPacksSelection={setCustomiseNoOfPacksSelection}
                      setCustomiseWeeksSelection={setCustomiseWeeksSelection}
                    />
                  </PlanCtx.Provider>
                  <FaqAccordion
                    faqs={faqs}
                    backgroundColor={'#A6C7D1'}
                    headingText={'FAQs'}
                    headingTextColor={'primary'}
                    faqQuestionColor={'primary'}
                    faqAnswerColor={'primary'}
                    headingType={HeadingType.P}
                    headingClass={HeadingClass.H3}
                    iconClass={'text-white'}
                  />
                  <p className="mb-8 text-center">Call us on 01743 384 562 with any questions</p>
                </div>
                <StickyFooter className="fixed bottom-0 left-0 right-0 z-[3] lg:max-h-20 bg-[#F8F2EC] w-full lg:w-11/12 mx-auto lg:rounded-t-3xl shadow-[0_-5px_15px_rgba(0,0,0,0.25)]">
                  <div className="w-full lg:w-full flex flex-col lg:flex-row relative items-center justify-between mx-auto pb-2">
                    <button
                      onClick={backClick}
                      type="button"
                      className="z-2 rounded-full border border-primary bg-white px-12 py-3 hover:underline focus:underline hidden lg:flex justify-center items-center"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4 mr-2" aria-hidden="true" />
                      Back
                    </button>
                    <div className="z-1 lg:mb-4 lg:w-1/2 text-center lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2">
                      {getPrice() > 0 && (
                        <div className="relative lg:top-4 mx-auto lg:right-16">
                          <span className="relative top-0 z-10 text-center text-primary">
                            {packType === 'FULL' && <span className="font-bold">Full Plan</span>}{' '}
                            {packType === 'PARTIAL' && <span className="font-bold">Partial Plan</span>} Price:{' '}
                            <span className="font-bold">£{(getPrice() / 100)?.toFixed(2)}</span> (
                            <span className="font-bold">£{(getCostPerDay() / 100)?.toFixed(2)}</span> a day)
                          </span>
                        </div>
                      )}
                      {getPrice() === 0 && (
                        <div>
                          <span className="relative top-4 z-10 text-center md:top-0">
                            <span className="font-bold text-primary">Calculating...</span>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-row justify-center items-center w-11/12 lg:w-auto lg:justify-center">
                      <a
                        className={`mr-2 flex h-[3.5rem] w-[3.5rem] items-center text-primary lg:hidden`}
                        onClick={backClick}
                      >
                        <StaticImage src="../../../../images/back-arrow-mobile.png" alt="Back" />
                      </a>
                      <button
                        data-testid="chooseRecipes"
                        onClick={() => props.handleSubmit()}
                        disabled={
                          !props.isValid ||
                          loading ||
                          (openTab === 'CUSTOM' &&
                            !selectionSameAsState(
                              customiseWeeksSelection,
                              customiseNoOfPacksSelection,
                              customisePackSizeSelection
                            ))
                        }
                        type="button"
                        className={`${
                          !props.isValid || loading ? '!bg-[#80B2C2]' : ''
                        } btn-cta scale-animation !py-3 lg:!py-4 !text-xl !font-bold !flex justify-center items-center w-11/12 lg:w-auto lg:!px-24 z-2`}
                      >
                        {loading ? (
                          <div className="align-center flex justify-center">
                            <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                            <div>Saving</div>
                          </div>
                        ) : (
                          'Next'
                        )}
                        {!loading && (
                          <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4 ml-2" aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  </div>
                </StickyFooter>
              </div>
            </>
          )}
        </Formik>
      </div>
      {error && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={''}
          url={null}
          action={null}
          actionText={null}
        />
      )}
      <YesNoModal show={yesNoModalShow} setShow={setYesNoModalShow} setYes={setYesAnswer} />
    </div>
  )
}

export default Plan
