import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player/lazy'
import screenfull from 'screenfull'

import './index.css'

enum PlaybackSettings {
  MutedWithAutoPlayOff = 'Muted with auto play off',
  MutedWithAutoPlayOn = 'Muted with auto play on',
  SoundOnWithAutoPlayOff = 'Sound on with auto play off'
}
interface VideoPlayerProps {
  videoSrc: any
  videoLoop: boolean
}

const VideoPlayer = ({ videoSrc, videoLoop }: VideoPlayerProps) => {
  const playbackSettings = 'MutedWithAutoPlayOff'
  const [_playerReady, setPlayerReady] = useState(false)
  const [videoPlayed, setVideoPlayed] = useState(false)
  const [videoPlayedHover, setVideoPlayedHover] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [mouseIn, setMouseIn] = useState(false)
  const [hover, setHover] = useState(false)
  const [videoMuted, setVideoMuted] = useState(true)
  const [volHover, setVolHover] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [fullScreenHover, setFullScreenHover] = useState(false)
  const [closeFullScreenHover, setCloseFullScreenHover] = useState(false)

  const [isIphone, setIsIphone] = useState(false) // Currently used to setIsMobile, rather than isolate to iphone, TODO: isolate when cloudinary video player working
  const [lightMode, setLightMode] = useState(
    'https://cdn.differentdog.com/images/unsafe/quality:80/width:572/format:png/plain/https://cdn.builder.io/api/v1/image/assets%2F0e0b081978d0442ca5f36e71c7712df5%2Fbf3656a42e124241953fa05101f8797f'
  )

  useEffect(() => {
    // if (window.navigator && typeof window.navigator !== 'undefined') {
    //   if (window.navigator.userAgent.includes('iPhone')) {
    //     setIsIphone(true)
    //   }
    // }
    if (window.innerWidth <= 768) {
      setIsIphone(true)
    } else {
      setIsIphone(false)
    }
  }, [])

  const getPlaybackSettings = () => {
    let settings
    switch (playbackSettings) {
      case PlaybackSettings.MutedWithAutoPlayOn.toString(): {
        settings = {
          playing: true,
          muted: true
        }
        break
      }
      case PlaybackSettings.SoundOnWithAutoPlayOff.toString(): {
        settings = {
          playing: false,
          muted: false
        }
        break
      }
      default: {
        settings = {
          playing: false,
          muted: true
        }
        break
      }
    }
    return settings
  }

  useEffect(() => {
    const videoSetting = getPlaybackSettings()
    if (playbackSettings) {
      setPlaying(videoSetting?.playing)
      setVideoMuted(videoSetting?.muted)
    }
  }, [playbackSettings])

  const play = (e: any) => {
    e.stopPropagation()
    setPlaying(!playing)
    setLightMode('')
    setVideoPlayed(false)
  }

  const handleMouseEnter = () => {
    // console.log('mouse enter')
    setMouseIn(true)
  }

  const handleMouseLeave = () => {
    // console.log('mouse leave')
    setMouseIn(false)
  }

  const handleTouchStart = () => {
    setMouseIn(true)
  }

  const handleButtonEnter = () => {
    setHover(true)
  }

  const handleButtonLeave = () => {
    setHover(false)
  }

  const handleButtonTouchStart = () => {
    setHover(true)
  }

  const handleTouchEnd = () => {
    setTimeout(() => {
      // console.log('touch end after 500 ms')
      setMouseIn(false)
    }, 500)
  }

  const mute = (e: any) => {
    e.stopPropagation()
    setVideoMuted(!videoMuted)
  }
  const handleVolumeBtnEnter = () => {
    setVolHover(true)
  }
  const handleVolumeBtnLeave = () => {
    setVolHover(false)
  }
  const handleVolumeBtnTouchStart = () => {
    setVolHover(true)
  }
  const handleFullScreenBtnEnter = () => {
    setFullScreenHover(true)
  }
  const handleFullScreenBtnLeave = () => {
    setFullScreenHover(false)
  }
  const handleFullScreenBtnTouchStart = () => {
    setFullScreenHover(true)
  }
  const handleCloseFullScreenBtnEnter = () => {
    setCloseFullScreenHover(true)
  }
  const handleCloseFullScreenBtnLeave = () => {
    setCloseFullScreenHover(false)
  }
  const handleCloseFullScreenBtnTouchStart = () => {
    setCloseFullScreenHover(true)
  }
  const handleReplayBtnEnter = () => {
    setVideoPlayedHover(true)
  }
  const handleReplayBtnLeave = () => {
    setVideoPlayedHover(false)
  }

  const handleFullScreen = (e: any) => {
    e.stopPropagation()
    setFullScreen(!fullScreen)
    screenfull.toggle()
  }

  const renderPlayButton = () => {
    if (playing) {
      return null
    }

    return (
      <div
        className={` ${fullScreen ? 'video-player-button-wrapper_full_screen' : 'video-player-button-wrapper'}`}
        onClick={play}
        onMouseEnter={handleButtonEnter}
        onMouseLeave={handleButtonLeave}
        onTouchStart={handleButtonTouchStart}
      >
        <svg width="100%" height="100%" viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1_2)">
            <circle cx="69.5" cy="69.5" r="69.5" fill="#F9F5F1" />
            {hover && (
              <path
                d="M53 98.6329V39.367C53 34.5437 58.4028 31.6919 62.3851 34.4132L105.75 64.0461C109.237 66.4286 109.237 71.5714 105.75 73.9539L62.3851 103.587C58.4028 106.308 53 103.456 53 98.6329Z"
                fill="#004355"
              />
            )}
            {hover && (
              <path
                d="M48 98.6329V39.367C48 34.5437 53.4028 31.6919 57.3851 34.4132L100.75 64.0461C104.237 66.4286 104.237 71.5714 100.75 73.9539L57.3851 103.587C53.4028 106.308 48 103.456 48 98.6329Z"
                fill="#006685"
              />
            )}
            <path
              d="M48 98.6329V39.367C48 34.5437 53.4028 31.6919 57.3851 34.4132L100.75 64.0461C104.237 66.4286 104.237 71.5714 100.75 73.9539L57.3851 103.587C53.4028 106.308 48 103.456 48 98.6329Z"
              fill="#006685"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_2">
              <rect width="100%" height="100%" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    )
  }

  const renderPauseButton = () => {
    // console.log(mouseIn, playing)

    if (mouseIn && playing) {
      return (
        <div
          className={`${fullScreen ? 'video-player-button-wrapper_full_screen' : 'video-player-button-wrapper pause'}`}
          onClick={play}
          onMouseEnter={handleButtonEnter}
          onMouseLeave={handleButtonLeave}
          onTouchStart={handleButtonTouchStart}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 139 139" fill="none">
            <g clipPath="url(#clip0_5_6)">
              <circle cx="69.5" cy="69.5" r="69.5" fill="#F9F5F1" />
              {hover && <rect x="42" y="28.2923" width="22" height="82" rx="6" fill="#004355" />}
              {hover && <rect x="82.7047" y="28" width="22" height="82" rx="6" fill="#004355" />}
              {hover && <rect x="39" y="28.2923" width="22" height="82" rx="6" fill="#006685" />}
              {hover && <rect x="79.7047" y="28" width="22" height="82" rx="6" fill="#006685" />}
              <rect x="39" y="28.2923" width="22" height="82" rx="6" fill="#006685" />
              <rect x="79.7047" y="28" width="22" height="82" rx="6" fill="#006685" />
            </g>
            <defs>
              <clipPath id="clip0_5_6">
                <rect width="100%" height="100%" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )
    }
    return null
  }

  const renderReplayButton = () => {
    if (!videoPlayed || playing) {
      return null
    }

    return (
      <div
        className={`${fullScreen ? 'video-player-button-wrapper_full_screen' : 'video-player-button-wrapper'}`}
        onClick={play}
        onMouseEnter={handleReplayBtnEnter}
        onMouseLeave={handleReplayBtnLeave}
        onTouchStart={handleButtonTouchStart}
      >
        <svg width="100%" height="100%" viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1_2)">
            <circle cx="69.5" cy="69.5" r="69.5" fill="#F9F5F1" />
            {hover && (
              <path
                d="M53 98.6329V39.367C53 34.5437 58.4028 31.6919 62.3851 34.4132L105.75 64.0461C109.237 66.4286 109.237 71.5714 105.75 73.9539L62.3851 103.587C58.4028 106.308 53 103.456 53 98.6329Z"
                fill="#004355"
              />
            )}
            {videoPlayedHover && (
              <path
                d="M72.6475 25C81.3498 25 89.8568 27.6099 97.0926 32.4996C104.328 37.3893 109.968 44.3393 113.298 52.4706C116.628 60.6019 117.5 69.5494 115.802 78.1815C114.104 86.8137 109.914 94.7428 103.76 100.966C97.6067 107.19 89.7666 111.428 81.2314 113.145C72.6963 114.862 63.8494 113.981 55.8094 110.613C47.7695 107.245 40.8976 101.541 36.0628 94.2229C32.3315 88.5751 29.9428 82.1616 29.0469 75.4824C28.6063 72.1981 31.3338 69.5 34.6475 69.5H40.906C44.2197 69.5 46.8378 72.2203 47.5868 75.4483C48.288 78.4699 49.5215 81.3563 51.2442 83.9637C54.0727 88.245 58.093 91.5819 62.7966 93.5523C67.5003 95.5228 72.676 96.0383 77.6694 95.0338C82.6628 94.0293 87.2495 91.5498 90.8495 87.9088C94.4495 84.2679 96.9011 79.6291 97.8944 74.579C98.8876 69.5289 98.3779 64.2943 96.4295 59.5372C94.4812 54.7801 91.1819 50.7141 86.9487 47.8535C82.7155 44.9928 77.7387 43.466 72.6475 43.466V25Z"
                fill="#004355"
              />
            )}
            {videoPlayedHover && (
              <path
                d="M74.4477 52.4706V19.2155C74.4477 16.509 71.4161 14.9089 69.1816 16.4358L44.8486 33.0633C42.8923 34.4002 42.8923 37.2859 44.8486 38.6227L69.1816 55.2503C71.4161 56.7772 74.4477 55.177 74.4477 52.4706Z"
                fill="#004355"
              />
            )}
            {videoPlayedHover && (
              <path
                d="M69.6475 25C78.3498 25 86.8568 27.6099 94.0926 32.4996C101.328 37.3893 106.968 44.3393 110.298 52.4706C113.628 60.6019 114.5 69.5494 112.802 78.1815C111.104 86.8137 106.914 94.7428 100.76 100.966C94.6067 107.19 86.7666 111.428 78.2314 113.145C69.6963 114.862 60.8494 113.981 52.8094 110.613C44.7695 107.245 37.8976 101.541 33.0628 94.2229C29.3315 88.5751 26.9428 82.1616 26.0469 75.4824C25.6063 72.1981 28.3338 69.5 31.6475 69.5H37.906C41.2197 69.5 43.8378 72.2203 44.5868 75.4483C45.288 78.4699 46.5215 81.3563 48.2442 83.9637C51.0727 88.245 55.093 91.5819 59.7966 93.5523C64.5003 95.5228 69.676 96.0383 74.6694 95.0338C79.6628 94.0293 84.2495 91.5498 87.8495 87.9088C91.4495 84.2679 93.9011 79.6291 94.8944 74.579C95.8876 69.5289 95.3779 64.2943 93.4295 59.5372C91.4812 54.7801 88.1819 50.7141 83.9487 47.8535C79.7155 44.9928 74.7387 43.466 69.6475 43.466V25Z"
                fill="#006685"
              />
            )}
            {videoPlayedHover && (
              <path
                d="M71.4477 52.4706V19.2155C71.4477 16.509 68.4161 14.9089 66.1816 16.4358L41.8486 33.0633C39.8923 34.4002 39.8923 37.2859 41.8486 38.6227L66.1816 55.2503C68.4161 56.7772 71.4477 55.177 71.4477 52.4706Z"
                fill="#006685"
              />
            )}

            <path
              d="M69.6475 25C78.3498 25 86.8568 27.6099 94.0926 32.4996C101.328 37.3893 106.968 44.3393 110.298 52.4706C113.628 60.6019 114.5 69.5494 112.802 78.1815C111.104 86.8137 106.914 94.7428 100.76 100.966C94.6067 107.19 86.7666 111.428 78.2314 113.145C69.6963 114.862 60.8494 113.981 52.8094 110.613C44.7695 107.245 37.8976 101.541 33.0628 94.2229C29.3315 88.5751 26.9428 82.1616 26.0469 75.4824C25.6063 72.1981 28.3338 69.5 31.6475 69.5H37.906C41.2197 69.5 43.8378 72.2203 44.5868 75.4483C45.288 78.4699 46.5215 81.3563 48.2442 83.9637C51.0727 88.245 55.093 91.5819 59.7966 93.5523C64.5003 95.5228 69.676 96.0383 74.6694 95.0338C79.6628 94.0293 84.2495 91.5498 87.8495 87.9088C91.4495 84.2679 93.9011 79.6291 94.8944 74.579C95.8876 69.5289 95.3779 64.2943 93.4295 59.5372C91.4812 54.7801 88.1819 50.7141 83.9487 47.8535C79.7155 44.9928 74.7387 43.466 69.6475 43.466V25Z"
              fill="#006685"
            />
            <path
              d="M71.4477 52.4706V19.2155C71.4477 16.509 68.4161 14.9089 66.1816 16.4358L41.8486 33.0633C39.8923 34.4002 39.8923 37.2859 41.8486 38.6227L66.1816 55.2503C68.4161 56.7772 71.4477 55.177 71.4477 52.4706Z"
              fill="#006685"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_2">
              <rect width="100%" height="100%" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    )
  }

  const renderVolumeOnButton = () => {
    if (!videoMuted) {
      return (
        <div
          className="absolute bottom-4 left-4 z-100"
          onClick={mute}
          onMouseEnter={handleVolumeBtnEnter}
          onMouseLeave={handleVolumeBtnLeave}
          onTouchStart={handleVolumeBtnTouchStart}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 139 139" fill="none">
            <g clipPath="url(#clip0_19_2)">
              <circle cx="69.5" cy="69.5" r="69.5" fill="#F9F5F1" />
              {volHover && (
                <path
                  d="M45.5501 53.875H30C26.6863 53.875 24 56.5613 24 59.875V80.125C24 83.4387 26.6863 86.125 30 86.125H45.5501L62.25 102.786C66.0318 106.559 72.4877 103.881 72.4877 98.5385V41.4615C72.4877 36.1194 66.0318 33.4409 62.25 37.2139L45.5501 53.875ZM110.326 100.224C108.473 102.522 105.064 102.5 102.974 100.415C100.869 98.3148 100.91 94.9219 102.7 92.5471C104.585 90.0449 106.154 87.3114 107.364 84.4079C109.268 79.8418 110.248 74.9453 110.248 70C110.248 65.0547 109.268 60.1582 107.364 55.5921C106.154 52.6886 104.585 49.9551 102.7 47.4529C100.91 45.0781 100.869 41.6852 102.974 39.5849C105.064 37.4998 108.473 37.4782 110.326 39.7762C113.188 43.3247 115.537 47.2669 117.296 51.4888C119.741 57.3554 121 63.6464 121 70C121 76.3536 119.741 82.6446 117.296 88.5112C115.537 92.7331 113.188 96.6753 110.326 100.224ZM94.9833 84.8917C93.3245 87.3635 89.8597 87.3316 87.7523 85.2291C85.6309 83.1126 85.737 79.6895 87.0384 76.9903C88.0803 74.8295 88.6361 72.4433 88.6361 70C88.6361 67.5567 88.0803 65.1705 87.0384 63.0097C85.737 60.3105 85.6309 56.8874 87.7523 54.7709C89.8597 52.6685 93.3245 52.6365 94.9833 55.1083C95.95 56.5489 96.7766 58.0833 97.4491 59.691C98.8152 62.9571 99.5187 66.461 99.5187 70C99.5187 73.539 98.8152 77.0429 97.4491 80.309C96.7766 81.9167 95.95 83.4511 94.9833 84.8917Z"
                  fill="#004355"
                />
              )}
              {volHover && (
                <path
                  d="M42.5501 53.875H27C23.6863 53.875 21 56.5613 21 59.875V80.125C21 83.4387 23.6863 86.125 27 86.125H42.5501L59.25 102.786C63.0318 106.559 69.4877 103.881 69.4877 98.5385V41.4615C69.4877 36.1194 63.0318 33.4409 59.25 37.2139L42.5501 53.875ZM107.326 100.224C105.473 102.522 102.064 102.5 99.9738 100.415C97.8685 98.3148 97.9097 94.9219 99.6995 92.5471C101.585 90.0449 103.154 87.3114 104.364 84.4079C106.268 79.8418 107.248 74.9453 107.248 70C107.248 65.0547 106.268 60.1582 104.364 55.5921C103.154 52.6886 101.585 49.9551 99.6995 47.4529C97.9097 45.0781 97.8685 41.6852 99.9738 39.5849C102.064 37.4998 105.473 37.4782 107.326 39.7762C110.188 43.3247 112.537 47.2669 114.296 51.4888C116.741 57.3554 118 63.6464 118 70C118 76.3536 116.741 82.6446 114.296 88.5112C112.537 92.7331 110.188 96.6753 107.326 100.224ZM91.9833 84.8917C90.3245 87.3635 86.8597 87.3316 84.7523 85.2291C82.6309 83.1126 82.737 79.6895 84.0384 76.9903C85.0803 74.8295 85.6361 72.4433 85.6361 70C85.6361 67.5567 85.0803 65.1705 84.0384 63.0097C82.737 60.3105 82.6309 56.8874 84.7523 54.7709C86.8597 52.6685 90.3245 52.6365 91.9833 55.1083C92.95 56.5489 93.7766 58.0833 94.4491 59.691C95.8152 62.9571 96.5187 66.461 96.5187 70C96.5187 73.539 95.8152 77.0429 94.4491 80.309C93.7766 81.9167 92.95 83.4511 91.9833 84.8917Z"
                  fill="#006685"
                />
              )}
              <path
                d="M42.5501 53.875H27C23.6863 53.875 21 56.5613 21 59.875V80.125C21 83.4387 23.6863 86.125 27 86.125H42.5501L59.25 102.786C63.0318 106.559 69.4877 103.881 69.4877 98.5385V41.4615C69.4877 36.1194 63.0318 33.4409 59.25 37.2139L42.5501 53.875ZM107.326 100.224C105.473 102.522 102.064 102.5 99.9738 100.415C97.8685 98.3148 97.9097 94.9219 99.6995 92.5471C101.585 90.0449 103.154 87.3114 104.364 84.4079C106.268 79.8418 107.248 74.9453 107.248 70C107.248 65.0547 106.268 60.1582 104.364 55.5921C103.154 52.6886 101.585 49.9551 99.6995 47.4529C97.9097 45.0781 97.8685 41.6852 99.9738 39.5849C102.064 37.4998 105.473 37.4782 107.326 39.7762C110.188 43.3247 112.537 47.2669 114.296 51.4888C116.741 57.3554 118 63.6464 118 70C118 76.3536 116.741 82.6446 114.296 88.5112C112.537 92.7331 110.188 96.6753 107.326 100.224ZM91.9833 84.8917C90.3245 87.3635 86.8597 87.3316 84.7523 85.2291C82.6309 83.1126 82.737 79.6895 84.0384 76.9903C85.0803 74.8295 85.6361 72.4433 85.6361 70C85.6361 67.5567 85.0803 65.1705 84.0384 63.0097C82.737 60.3105 82.6309 56.8874 84.7523 54.7709C86.8597 52.6685 90.3245 52.6365 91.9833 55.1083C92.95 56.5489 93.7766 58.0833 94.4491 59.691C95.8152 62.9571 96.5187 66.461 96.5187 70C96.5187 73.539 95.8152 77.0429 94.4491 80.309C93.7766 81.9167 92.95 83.4511 91.9833 84.8917Z"
                fill="#006685"
              />
            </g>
            <defs>
              <clipPath id="clip0_19_2">
                <rect width="139" height="139" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )
    }
    return null
  }

  const renderVolumeOffButton = () => {
    if (videoMuted) {
      return (
        <div
          className="absolute bottom-4 left-4 z-10"
          onClick={mute}
          onMouseEnter={handleVolumeBtnEnter}
          onMouseLeave={handleVolumeBtnLeave}
          onTouchStart={handleVolumeBtnTouchStart}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 139 139" fill="none">
            <g clipPath="url(#clip0_19_2)">
              <circle cx="69.5" cy="69.5" r="69.5" fill="#F9F5F1" />
              {volHover && (
                <path
                  d="M104.625 62.4213L97.0328 54.7932C94.9341 52.6846 91.522 52.6805 89.4183 54.7843C87.311 56.8915 87.3191 60.3104 89.4361 62.4077L97.1 70L89.4675 77.5967C87.3613 79.693 87.3573 83.101 89.4586 85.2023C91.5668 87.3105 94.9886 87.2984 97.0819 85.1754L104.625 77.525L112.222 85.1575C114.318 87.2637 117.726 87.2677 119.827 85.1665C121.936 83.0582 121.923 79.6364 119.8 77.5431L112.15 70L119.783 62.4033C121.889 60.307 121.893 56.899 119.792 54.7977C117.683 52.6895 114.261 52.7016 112.168 54.8246L104.625 62.475V62.4213Z"
                  fill="#004355"
                />
              )}
              {volHover && (
                <path
                  d="M45.5501 53.875H30C26.6863 53.875 24 56.5613 24 59.875V80.125C24 83.4387 26.6863 86.125 30 86.125H45.5501L62.25 102.786C66.0318 106.559 72.4877 103.881 72.4877 98.5385V41.4615C72.4877 36.1194 66.0318 33.4409 62.25 37.2139L45.5501 53.875Z"
                  fill="#004355"
                />
              )}
              {volHover && (
                <path
                  d="M101.625 62.4213L94.0328 54.7932C91.9341 52.6846 88.522 52.6805 86.4183 54.7843C84.311 56.8915 84.3191 60.3104 86.4361 62.4077L94.1 70L86.4675 77.5967C84.3613 79.693 84.3573 83.101 86.4586 85.2023C88.5668 87.3105 91.9886 87.2984 94.0819 85.1754L101.625 77.525L109.222 85.1575C111.318 87.2637 114.726 87.2677 116.827 85.1665C118.936 83.0582 118.923 79.6364 116.8 77.5431L109.15 70L116.783 62.4033C118.889 60.307 118.893 56.899 116.792 54.7977C114.683 52.6895 111.261 52.7016 109.168 54.8246L101.625 62.475V62.4213Z"
                  fill="#006685"
                />
              )}
              {volHover && (
                <path
                  d="M42.5501 53.875H27C23.6863 53.875 21 56.5613 21 59.875V80.125C21 83.4387 23.6863 86.125 27 86.125H42.5501L59.25 102.786C63.0318 106.559 69.4877 103.881 69.4877 98.5385V41.4615C69.4877 36.1194 63.0318 33.4409 59.25 37.2139L42.5501 53.875Z"
                  fill="#006685"
                />
              )}

              <path
                d="M101.625 62.4213L94.0328 54.7932C91.934 52.6846 88.522 52.6805 86.4182 54.7843C84.311 56.8915 84.319 60.3104 86.4361 62.4077L94.1 70L86.4675 77.5967C84.3613 79.693 84.3573 83.101 86.4585 85.2023C88.5668 87.3105 91.9886 87.2984 94.0819 85.1754L101.625 77.525L109.222 85.1575C111.318 87.2637 114.726 87.2677 116.827 85.1665C118.936 83.0582 118.923 79.6364 116.8 77.5431L109.15 70L116.783 62.4033C118.889 60.307 118.893 56.899 116.791 54.7977C114.683 52.6895 111.261 52.7016 109.168 54.8246L101.625 62.475V62.4213Z"
                fill="#006685"
              />
              <path
                d="M42.5501 53.875H27C23.6863 53.875 21 56.5613 21 59.875V80.125C21 83.4387 23.6863 86.125 27 86.125H42.5501L59.25 102.786C63.0318 106.559 69.4877 103.881 69.4877 98.5385V41.4615C69.4877 36.1194 63.0318 33.4409 59.25 37.2139L42.5501 53.875Z"
                fill="#006685"
              />
            </g>
            <defs xmlns="http://www.w3.org/2000/svg">
              <clipPath id="clip0_19_13">
                <rect width="139" height="139" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )
    }
    return null
  }

  const renderOpenFullScreenButton = () => {
    if (!fullScreen) {
      return (
        <div
          className="absolute bottom-4 right-4 z-10"
          onClick={handleFullScreen}
          onMouseEnter={handleFullScreenBtnEnter}
          onMouseLeave={handleFullScreenBtnLeave}
          onTouchStart={handleFullScreenBtnTouchStart}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 139 139" fill="none">
            <g clipPath="url(#clip0_19_2)">
              <circle cx="69.5" cy="69.5" r="69.5" fill="#F9F5F1" />
              {fullScreenHover && (
                <path
                  d="M33 35C33 31.6863 35.6863 29 39 29H57C60.3137 29 63 31.6863 63 35C63 38.3137 60.3137 41 57 41H45V53C45 56.3137 42.3137 59 39 59C35.6863 59 33 56.3137 33 53V35Z"
                  fill="#004355"
                />
              )}
              {fullScreenHover && (
                <path
                  d="M113 35C113 31.6863 110.314 29 107 29H89C85.6863 29 83 31.6863 83 35C83 38.3137 85.6863 41 89 41H101V53C101 56.3137 103.686 59 107 59C110.314 59 113 56.3137 113 53V35Z"
                  fill="#004355"
                />
              )}
              {fullScreenHover && (
                <path
                  d="M33 103C33 106.314 35.6863 109 39 109H57C60.3137 109 63 106.314 63 103C63 99.6863 60.3137 97 57 97H45V85C45 81.6863 42.3137 79 39 79C35.6863 79 33 81.6863 33 85V103Z"
                  fill="#004355"
                />
              )}
              {fullScreenHover && (
                <path
                  d="M113 103C113 106.314 110.314 109 107 109H89C85.6863 109 83 106.314 83 103C83 99.6863 85.6863 97 89 97H101V85C101 81.6863 103.686 79 107 79C110.314 79 113 81.6863 113 85V103Z"
                  fill="#004355"
                />
              )}
              {fullScreenHover && (
                <path
                  d="M30 35C30 31.6863 32.6863 29 36 29H54C57.3137 29 60 31.6863 60 35C60 38.3137 57.3137 41 54 41H42V53C42 56.3137 39.3137 59 36 59C32.6863 59 30 56.3137 30 53V35Z"
                  fill="#006685"
                />
              )}
              {fullScreenHover && (
                <path
                  d="M110 35C110 31.6863 107.314 29 104 29H86C82.6863 29 80 31.6863 80 35C80 38.3137 82.6863 41 86 41H98V53C98 56.3137 100.686 59 104 59C107.314 59 110 56.3137 110 53V35Z"
                  fill="#006685"
                />
              )}
              {fullScreenHover && (
                <path
                  d="M30 103C30 106.314 32.6863 109 36 109H54C57.3137 109 60 106.314 60 103C60 99.6863 57.3137 97 54 97H42V85C42 81.6863 39.3137 79 36 79C32.6863 79 30 81.6863 30 85V103Z"
                  fill="#006685"
                />
              )}
              {fullScreenHover && (
                <path
                  d="M110 103C110 106.314 107.314 109 104 109H86C82.6863 109 80 106.314 80 103C80 99.6863 82.6863 97 86 97H98V85C98 81.6863 100.686 79 104 79C107.314 79 110 81.6863 110 85V103Z"
                  fill="#006685"
                />
              )}
              <path
                d="M30 35C30 31.6863 32.6863 29 36 29H54C57.3137 29 60 31.6863 60 35C60 38.3137 57.3137 41 54 41H42V53C42 56.3137 39.3137 59 36 59C32.6863 59 30 56.3137 30 53V35Z"
                fill="#006685"
              />
              <path
                d="M110 35C110 31.6863 107.314 29 104 29H86C82.6863 29 80 31.6863 80 35C80 38.3137 82.6863 41 86 41H98V53C98 56.3137 100.686 59 104 59C107.314 59 110 56.3137 110 53V35Z"
                fill="#006685"
              />
              <path
                d="M30 103C30 106.314 32.6863 109 36 109H54C57.3137 109 60 106.314 60 103C60 99.6863 57.3137 97 54 97H42V85C42 81.6863 39.3137 79 36 79C32.6863 79 30 81.6863 30 85V103Z"
                fill="#006685"
              />
              <path
                d="M110 103C110 106.314 107.314 109 104 109H86C82.6863 109 80 106.314 80 103C80 99.6863 82.6863 97 86 97H98V85C98 81.6863 100.686 79 104 79C107.314 79 110 81.6863 110 85V103Z"
                fill="#006685"
              />
            </g>
            <defs xmlns="http://www.w3.org/2000/svg">
              <clipPath id="clip0_19_13">
                <rect width="139" height="139" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )
    }
    return null
  }

  const renderCloseFullScreenButton = () => {
    if (fullScreen) {
      return (
        <div
          className="absolute bottom-4 right-4 z-10"
          onClick={handleFullScreen}
          onMouseEnter={handleCloseFullScreenBtnEnter}
          onMouseLeave={handleCloseFullScreenBtnLeave}
          onTouchStart={handleCloseFullScreenBtnTouchStart}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 139 139" fill="none">
            <g clipPath="url(#clip0_19_2)">
              <circle cx="69.5" cy="69.5" r="69.5" fill="#F9F5F1" />

              {closeFullScreenHover && (
                <path
                  d="M83 54C83 57.3137 85.6863 60 89 60H107C110.314 60 113 57.3137 113 54V54C113 50.6863 110.314 48 107 48H95V36C95 32.6863 92.3137 30 89 30V30C85.6863 30 83 32.6863 83 36V54Z"
                  fill="#004355"
                />
              )}
              {closeFullScreenHover && (
                <path
                  d="M63 54C63 57.3137 60.3137 60 57 60H39C35.6863 60 33 57.3137 33 54V54C33 50.6863 35.6863 48 39 48H51V36C51 32.6863 53.6863 30 57 30V30C60.3137 30 63 32.6863 63 36V54Z"
                  fill="#004355"
                />
              )}
              {closeFullScreenHover && (
                <path
                  d="M63 86C63 82.6863 60.3137 80 57 80H39C35.6863 80 33 82.6863 33 86V86C33 89.3137 35.6863 92 39 92H51V104C51 107.314 53.6863 110 57 110V110C60.3137 110 63 107.314 63 104V86Z"
                  fill="#004355"
                />
              )}
              {closeFullScreenHover && (
                <path
                  d="M79 86C79 82.6863 81.6863 80 85 80H103C106.314 80 109 82.6863 109 86V86C109 89.3137 106.314 92 103 92H91V104C91 107.314 88.3137 110 85 110V110C81.6863 110 79 107.314 79 104V86Z"
                  fill="#004355"
                />
              )}
              {closeFullScreenHover && (
                <path
                  d="M80 54C80 57.3137 82.6863 60 86 60H104C107.314 60 110 57.3137 110 54V54C110 50.6863 107.314 48 104 48H92V36C92 32.6863 89.3137 30 86 30V30C82.6863 30 80 32.6863 80 36V54Z"
                  fill="#006685"
                />
              )}
              {closeFullScreenHover && (
                <path
                  d="M60 54C60 57.3137 57.3137 60 54 60H36C32.6863 60 30 57.3137 30 54V54C30 50.6863 32.6863 48 36 48H48V36C48 32.6863 50.6863 30 54 30V30C57.3137 30 60 32.6863 60 36V54Z"
                  fill="#006685"
                />
              )}
              {closeFullScreenHover && (
                <path
                  d="M60 86C60 82.6863 57.3137 80 54 80H36C32.6863 80 30 82.6863 30 86V86C30 89.3137 32.6863 92 36 92H48V104C48 107.314 50.6863 110 54 110V110C57.3137 110 60 107.314 60 104V86Z"
                  fill="#006685"
                />
              )}
              {closeFullScreenHover && (
                <path
                  d="M76 86C76 82.6863 78.6863 80 82 80H100C103.314 80 106 82.6863 106 86V86C106 89.3137 103.314 92 100 92H88V104C88 107.314 85.3137 110 82 110V110C78.6863 110 76 107.314 76 104V86Z"
                  fill="#006685"
                />
              )}

              <path
                d="M80 54C80 57.3137 82.6863 60 86 60H104C107.314 60 110 57.3137 110 54V54C110 50.6863 107.314 48 104 48H92V36C92 32.6863 89.3137 30 86 30V30C82.6863 30 80 32.6863 80 36V54Z"
                fill="#006685"
              />
              <path
                d="M60 54C60 57.3137 57.3137 60 54 60H36C32.6863 60 30 57.3137 30 54V54C30 50.6863 32.6863 48 36 48H48V36C48 32.6863 50.6863 30 54 30V30C57.3137 30 60 32.6863 60 36V54Z"
                fill="#006685"
              />
              <path
                d="M60 86C60 82.6863 57.3137 80 54 80H36C32.6863 80 30 82.6863 30 86V86C30 89.3137 32.6863 92 36 92H48V104C48 107.314 50.6863 110 54 110V110C57.3137 110 60 107.314 60 104V86Z"
                fill="#006685"
              />
              <path
                d="M76 86C76 82.6863 78.6863 80 82 80H100C103.314 80 106 82.6863 106 86V86C106 89.3137 103.314 92 100 92H88V104C88 107.314 85.3137 110 82 110V110C78.6863 110 76 107.314 76 104V86Z"
                fill="#006685"
              />
            </g>
            <defs xmlns="http://www.w3.org/2000/svg">
              <clipPath id="clip0_19_13">
                <rect width="139" height="139" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )
    }
    return null
  }

  return (
    <>
      {/* {!isIphone && ( */}
      <div
        onClick={play}
        className={`w-31 relative h-60 ${
          fullScreen ? 'video-player-full-screen react-player-fullscreen' : 'react-player'
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {renderPlayButton()}
        {renderPauseButton()}
        {renderVolumeOnButton()}
        {renderVolumeOffButton()}
        {renderCloseFullScreenButton()}
        {playing && !isIphone && renderOpenFullScreenButton()}
        {renderReplayButton()}
        <ReactPlayer
          url={videoSrc || ''}
          playing={playing}
          onReady={() => setPlayerReady(true)}
          light={lightMode}
          loop={false}
          onEnded={() => {
            setVideoPlayed(true)
            setPlaying(false)
          }}
          width={'100%'}
          height={'100%'}
          muted={videoMuted}
          config={{
            file: {
              attributes: {
                controls: false,
                preload: 'none'
              }
            }
          }}
          controls={false}
          playsinline={true}
        />
      </div>
      {/* )} */}

      {/* {isIphone && (
        <div
          className={`w-31 relative h-60 ${
            fullScreen ? 'video-player-full-screen react-player-fullscreen' : 'react-player'
          }`}
        >
          <iframe
            src={videoSrc || ''}
            width="100%"
            className={`relative h-60 rounded-2xl ${
              fullScreen ? 'video-player-full-screen react-player-fullscreen' : 'react-player'
            }`}
            allow="fullscreen; encrypted-media; picture-in-picture"
          ></iframe>
        </div>
      )} */}
    </>
  )
}

export default VideoPlayer
