import ErrorModal from 'components/ui/modal/ErrorModal'
import { Form, Formik } from 'formik'
import { Link, navigate } from 'gatsby'
import ChangeDeliveryFrequencyImg from 'images/cancel-pause/Frequency.svg'
import ChangePlanImg from 'images/cancel-pause/Plan.svg'
import ReferImg from 'images/cancel-pause/Reffer.svg'
import StarImg from 'images/cancel-pause/Star.svg'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updateDogsToDelete, updatePlanRadio } from 'state/actions/account/accountDogAction'
import { createDogsExistingPlan, updateChangedStatus } from 'state/actions/account/flow/dogAction'
import Api from 'state/apis/api'
import loadingGIF from '../../../../../images/loadingspinner.gif'

const Refer: React.FC = ({ subscriptionId, actionType }) => {
  const dispatch = useDispatch()
  const { dogsToDelete } = useSelector((state: RootState) => state.accountDog)
  const { summary } = useSelector((state: RootState) => state.accountSummary)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { changed } = useSelector((state: RootState) => state.accountDogFlow)
  const planStatus = useSelector((state: RootState) => state.accountPlan.planStatus)
  const [loading, _setLoading] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (changed) {
      const url = spoofMode ? '/account/plan-recipes/?userId=' + spoofedUserId : '/account/plan-recipes/'
      navigate(url)
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    if (Object.keys(planStatus).length === 0) {
      navigate(redirectUrl)
    }
  }, [planStatus])

  useEffect(() => {
    if (typeof window?.yotpoWidgetsContainer?.initWidget !== 'undefined') {
      window.yotpoWidgetsContainer.initWidgets()
    }
  }, [])

  if (loading) {
    return (
      <div className="m-auto w-full text-center" style={{ marginTop: '25vh' }}>
        <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
      </div>
    )
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    const redirectUrl = spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`
    const pauseCancelUrl = spoofMode
      ? `/v1/private/admin/account/plan/${actionType}?userId=` + spoofedUserId
      : `/v1/private/account/plan/${actionType}`
    const subscriptionObject = {
      subscriptionId: subscriptionId,
      text: planStatus.other || '',
      reason: planStatus.reason,
      id: planStatus.id
    }
    let event = 'paused_plan'
    if (actionType === 'cancel') {
      if (dogsToDelete?.length === 1) {
        await dispatch(updateDogsToDelete([dogsToDelete[0].id]))
      }
      subscriptionObject.deleteDogs = dogsToDelete
      event = 'cancelled_plan'
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', event, {
        reason: 'Refer',
        subscription_id: subscriptionId
      })
    }

    Api.post('wwwREST', pauseCancelUrl, {
      body: subscriptionObject
    })
      .then(() => {
        navigate(redirectUrl)
        setSubmitting(false)
      })
      .catch((err: any) => {
        if (err.response.data) {
          setErrorMessage(err.response.data)
        }
        setShowPopup(true)
        setSubmitting(false)
      })
  }

  return (
    <div className="mt-8">
      <div className="mb-2">
        <Link to="/account/plan/">&larr; Go back to plan</Link>
      </div>
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          await handleSubmit()
        }}
      >
        {_props => (
          <Form className="mx-auto w-full text-center md:max-w-[80%]">
            <h1 className="mb-8">Before you go...</h1>
            <p className="mb-8">
              If you are trying to save money but don't want to lose the nutritional benefits to our food, then here are
              some ways we can help:
            </p>

            <div className="mb-8">
              <div className="relative mb-4 rounded-3xl border border-4 border-[#F6E9DB] p-4">
                <div className="absolute -left-4 top-0 bottom-0 flex flex-col justify-center">
                  <img src={StarImg} alt="Star image" />
                </div>
                <p>
                  The best way to save on your deliveries is to <span className="font-bold">refer your friends. </span>
                  We'll give you £5 off for every box they order, and here's the best bit... it's unlimited! So, the
                  longer they feed our recipes, the more rewards you get.
                </p>
              </div>
              <div className="relative mb-4 rounded-3xl border border-4 border-[#F6E9DB] p-4">
                <div className="absolute -left-4 top-0 bottom-0 flex flex-col justify-center">
                  <img src={StarImg} alt="Star image" />
                </div>
                <p>
                  Consider <span className="font-bold">switching</span> from a complete Different Dog diet to a{' '}
                  <span className="font-bold">partial diet</span>. Studies suggest that feeding as little as 20% fresh
                  has significant health benefits, so feeding our recipes as a topper is an affordable way of accessing
                  the nutrients your dog needs to thrive.
                </p>
              </div>
              <div className="relative mb-4 rounded-3xl border border-4 border-[#F6E9DB] p-4">
                <div className="absolute -left-4 top-0 bottom-0 flex flex-col justify-center">
                  <img src={StarImg} alt="Star image" />
                </div>
                <p>
                  You and your four-legged-friend are important to us, so why not call our Customer Delight team on{' '}
                  <span className="font-bold">01743 384 562</span> and quote "<span className="font-bold">CRUMBLE</span>
                  " for a small discount on your next box.
                </p>
              </div>
            </div>
            <div
              id="swell-customer-identification"
              data-authenticated="true"
              data-email={summary?.customer?.email}
              data-id={summary?.customer?.customerId}
              className="display:none;"
            ></div>
            {process.env.GATSBY_YOTPO_REDEEM_POINTS_MODULE && (
              <div
                className="yotpo-widget-instance"
                data-yotpo-instance-id={process.env.GATSBY_YOTPO_REDEEM_POINTS_MODULE}
              ></div>
            )}
            <h3 className="mb-8">Here are some options for you</h3>

            <div className="mb-8">
              <div className="flex flex-row flex-wrap justify-center">
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[250px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        if (subscriptionId) {
                          dispatch(updatePlanRadio(subscriptionId))
                          dispatch(
                            createDogsExistingPlan({
                              subscriptionId: subscriptionId,
                              dogs: []
                            })
                          )
                        }
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={ChangePlanImg} alt="Change plan" />
                      <p className="max-w-[80%]">Change plan</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[250px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        if (subscriptionId) {
                          dispatch(updatePlanRadio(subscriptionId))
                          dispatch(
                            createDogsExistingPlan({
                              subscriptionId: subscriptionId,
                              dogs: []
                            })
                          )
                        }
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={ChangeDeliveryFrequencyImg} alt="Change delivery frequency" />
                      <p className="max-w-[80%]">Change delivery frequency</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center my-1">
                  <div className="flex flex-col items-center justify-center rounded-3xl hover:bg-[#B0CDD5] focus:bg-[#B0CDD5] border-2 border-[#006685] min-h-[180px] min-w-[250px] mx-1">
                    <button
                      type="button"
                      onClick={() => {
                        const url = spoofMode ? '/account/rewards/?userId=' + spoofedUserId : '/account/rewards/'
                        navigate(url)
                      }}
                      className="flex flex-col items-center justify-center p-4"
                    >
                      <img className="mb-2" src={ReferImg} alt="Refer a friend and earn free food!" />
                      <p className="max-w-[80%]">Refer a friend and earn free food!</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-auto mb-8 flex w-max flex-col">
              <button type="submit" className="btn-cta mb-4 disabled:opacity-50" disabled={isSubmitting}>
                {actionType === 'cancel' ? 'Cancel' : 'Pause'} plan
              </button>
              <button
                type="button"
                onClick={() => {
                  navigate(spoofMode ? `/account/plan/?userId=` + spoofedUserId : `/account/plan/`)
                }}
                className="btn-primary"
              >
                I don't want to {actionType === 'cancel' ? 'cancel' : 'pause'} anymore
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mx-auto w-full text-center">
        <Link className="btn-secondary !no-underline" to={`/account/plan/${subscriptionId}/status/${actionType}`}>
          Back
        </Link>
      </div>
      {errorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
          url={errorMessage?.popup?.url ?? errorMessage?.url}
          action={errorMessage?.popup?.action ?? errorMessage?.action}
          actionText={errorMessage?.popup?.actionText ?? errorMessage?.actionText}
        />
      )}
    </div>
  )
}

export default Refer
